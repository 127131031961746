import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// @ts-ignore
import styles from "./Hero.module.css";

interface HeroProps {
  setFormIsOpen: (value: boolean) => void;
}

const Hero = ({ setFormIsOpen }: HeroProps) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <Box sx={{
    mt: -1,
    background: "url(/images/trader-files/main.png)",
    backgroundSize: width > 1914 ? "100%" : "",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundPositionX: "center",
    position: "relative"
  }}
  >
    <Container maxWidth="xl" sx={{ overflow: "hidden", minHeight: width < 650 && width > 450 ? 780 : "100%", height: { xs: 655, sm: 700, md: 1068 }, pb: "5vh" }}>
      <Grid container pt={{ xs: "120px", md: "220px" }} sx={{}} >
        <Grid item xs={12} sm={6}>
          <Stack mt={{ sm: 0, md: 3.5 }} alignItems="start" width="100%">
            <Typography zIndex={1} fontSize={{ xs: 32, sm: 59, md: 90 }} fontWeight="bold" lineHeight={{ xs: "32px", sm: "59px", md: "95px" }} color="#fff">13 файлов <br /> для  трейдера</Typography>
            <Typography zIndex={1} fontSize={{ xs: 13, sm: 19, md: 30 }} fontWeight="300" mt={{ xs: 2, md: 5 }} color="#fff">
              от ТОПовой команды,<br /> которые сэкономят тебе <br /> месяцы работы и кучу слитых денег!
            </Typography>
            <Box display="flex" zIndex={1} justifyContent="center" mt={{ xs: 2, md: 7 }}  >
              <Button onClick={() => {
                //@ts-ignore
                ym(96414864, 'reachGoal', 'button_form')
                setFormIsOpen(true)
              }}
                variant="contained" sx={{ boxShadow: "none", background: "linear-gradient(to right, #b41c17, 70%, #dd3c17)", textTransform: "none", borderRadius: 6, px: { xs: 3, md: 6 }, fontSize: { xs: 13, sm: 14, md: 16 }, height: { xs: 42, sm: 54, md: 76 } }}>Забрать файлы бесплатно</Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} position="relative">
          <Box sx={{
            position: "absolute",
            display: "flex", zIndex: 1, justifyContent: "center",
            top: { xs: 20, sm: 0, md: -50 },
            right: {xs: 50, sm: 0, md: -10},
            "& img": { width: "108%"}
          }}
            position="relative"
          >
            <div className={styles.HeroImageWrapper}>
              <img src={"/images/trader-files/card-mockup.png"} alt={"team"} />
            </div>
          </Box>

        </Grid>
      </Grid>

    </Container>
  </Box>
}


export default Hero;